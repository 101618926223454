<template>
  <div class="english">
    <web-header language="en" ref="webHeaderRef"></web-header>
    <div class="english-wrap">
      <div class="english-wrap-head">
        <div class="english-wrap-head-tab">
          <div v-for="(item, index) in reportClass" @click="changeClass(item.id)" :class="{ 'english-wrap-head-tab-cell': true, 'active': selectedClass == item.id }" :key="index">
            {{ item.class_name }}
          </div>
        </div>
        <div class="english-wrap-head-search">
          <el-input v-model="keywords" prefix-icon="el-icon-search" placeholder="Please enter keywords" size="medium" @keyup.native.enter="searchData" @clear="searchData" clearable></el-input>
        </div>
      </div>
      <div class="english-wrap-content">
        <div class="english-wrap-content-left" v-loading="loading">
          <div class="english-wrap-content-left-cell" v-for="(item, index) in dataList" :key="index">
            <a :href="'/report/english/'+item.id" target="_blank" class="english-wrap-content-left-cell-title">
              <span>{{ item.product_name }}</span>
              <img :src="item.product_class.small_icon.img" v-if="item.product_class && item.product_class.small_icon && item.product_class.small_icon.img" />
            </a>
            <div class="english-wrap-content-left-cell-desc">
              {{ removeHtmlTags(item.content) }}
            </div>
            <div class="english-wrap-content-left-cell-tag">
              <span>{{ $moment(item.publish_time).format("LL") }}</span>
              <span>{{ item.author }}</span>
            </div>
          </div>
          <el-empty :description="loading ? 'loading...' : 'No Data'" v-if="!dataList || dataList.length == 0"></el-empty>
        </div>
        <div class="english-wrap-content-page">
          <el-button type="danger" size="medium" round @click="dataMore" v-if="!noneMore && page.total > page.pageSize">View More</el-button>
          <div class="none-more" v-else>There's nothing more</div>
        </div>
        <div class="english-wrap-content-right">
          <div class="english-wrap-content-right-card" v-if="reportSubject && reportSubject.length > 0">
            <div class="english-wrap-content-right-card-title">
              CF40 Recommend
            </div>
            <swiper @slideChangeTransitionStart="slideChange" :options="swiperOptions" class="carousel-swiper" ref="carouselSwiper">
              <swiper-slide v-for="(item, index) in reportSubject" :key="index">
                <a :href="'/special/english/'+item.id" target="_blank">
                  <img :src="item.icon.img"/>
                </a>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
          <div class="english-wrap-content-right-card">
            <div class="english-wrap-content-right-card-title">
              About CF40 Reserach
            </div>
            <div class="english-wrap-content-right-card-desc">
              On July 8, the People's Bank of China announced that it would carry out temporary overnight repo or reverse repo operations , with interest rates of 20bp minus and 50bp plus for the 7-day reverse repo operation , which attracted widespread attention from the market. This article points out that under the new mechanism, the central bank can indeed regulate short-term liquidity more accurately and timely, and at the same time can better promote DR007 to fluctuate around the 7-day reverse repo operation interest rate. This is a further improvement of the existing liquidity management mechanism and interest rate corridor system, but it cannot be regarded as the formation of a new interest rate corridor mechanism. According to different policy intentions, there is still room for further optimization of the new mechanism in terms of the counterparty of the tool, the trigger mechanism and the scale of operation.
            </div>
            <div class="english-wrap-content-right-card-opera" v-if="singleList && singleList.length > 0">
              <template v-for="(item, index) in singleList">
                <a :href="item.link_url ? item.link_url : '/single/english/'+item.id" target="_blank" :key="index">{{ item.title }}</a>
              </template>
            </div>
          </div>
          <div class="english-wrap-content-right-card">
            <div class="english-wrap-content-right-card-title">
              Contact
            </div>
            <div class="english-wrap-content-right-card-desc">
              <p>
                Beijing Representative Office: Ms. Tao+86 18911263563
              </p>
              <p>
                Shanghai Representative Office: Ms. Zhang+86 18911263563
              </p>
              <p>
                Guangzhou Representative Office: Ms. Xu+86 18911263563
              </p>
              <p>
                E-mail: cf40research@cf40.org.cn
              </p>
            </div>
          </div>
          <div class="english-wrap-content-right-card">
            <div class="english-wrap-content-right-card-icon">
              <div class="qrcode">
                <img src="~@/assets/qr_code.jpg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="english-wrap-pagination" v-if="page.total > page.pageSize">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page.current"
            :page-size="page.pageSize"
            layout="pager"
            :total="page.total">
        </el-pagination>
      </div>
    </div>
    <div class="english-join" v-if="isTip">
      <div class="english-join-describe">
        <div class="english-join-describe-header">
          JOIN CF40 RESEARCH VIP
        </div>
        <div class="english-join-describe-tip">
          READ MORE VIP ARTICLES
        </div>
        <div class="english-join-describe-btn" @click="subscribe">JOIN US</div>
      </div>
      <div class="english-join-close" @click="isTip = false">
        <i class="el-icon-close"></i>
      </div>
    </div>
    <web-footer language="en"></web-footer>
  </div>
</template>

<script>
import { reportList, classifyList, subjectList, singleNewList } from "@/api";
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import SwiperMixin from "./swiperMixin";
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import WebHeader from "@/components/webHeader";
import WebFooter from "@/components/webFooter";
export default {
  name: "English",
  mixins: [SwiperMixin],
  components: {
    Swiper,
    SwiperSlide,
    WebHeader,
    WebFooter
  },
  data() {
    return {
      // 搜索关键词
      keywords: "",
      // 分类列表
      reportClass: [],
      // 分类字符串
      reportClassStr: "",
      // 选中的分类
      selectedClass: "",
      // 数据加载状态
      loading: false,
      // 数据列表
      dataList: [],
      page: {
        // 当前页
        current: 1,
        // 每页显示的条数
        pageSize: 10,
        orderBy: "publish_time",
        sortedBy: "desc",
        // 总条数
        total: 100
      },
      noneMore: false,
      isMobile: false,
      // 是否显示提示框
      isTip: true,
      // 专题列表
      reportSubject: [],
      // 单页列表
      singleList: [],
    }
  },
  created() {
    locale.use(lang);
    this.currentPage = 1;
    this.dataList = [];
    this.getClassList();
    this.getSubjectList();
    this.getSinglePages();
  },
  mounted() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile);
  },
  methods: {
    // 搜索
    searchData() {
      this.currentPage = 1;
      this.dataList = [];
      this.getReportList();
    },
    // 判断是否是手机端
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    // 轮播图切换
    slideChange() {
      const swiper = this.$refs.carouselSwiper.$swiper;
      if (swiper.slides && swiper.slides.length > 0) {
        for (let i = 0; i < swiper.slides.length; i++) {
          let activeClass = swiper.slides[i].className;
          if (activeClass.indexOf('active') > -1) {
            swiper.pagination.bullets[i].classList.add('swiper-pagination-bullet-active');
          } else {
            swiper.pagination.bullets[i].classList.remove('swiper-pagination-bullet-active');
          }
        }
      }
    },
    // 获取单页列表
    async getSinglePages() {
      let param = {
        pageSize: 30,
        currentPage: 1,
        orderBy: "publish_time",
        sortedBy: "desc",
        search: "status:1|lang:2",
        searchFields: "status:=|lang:="
      };
      let res = await singleNewList(param);
      if (res && res.code == 200) {
        this.singleList = res.data.list;
      }
    },
    // 获取分类列表
    async getClassList() {
      let param = {
        pageSize: 30,
        currentPage: 1,
        orderBy: "sort",
        sortedBy: "desc",
        search: "is_show:true|type:1",
        searchFields: "is_show:=|type:=",
      };
      let res = await classifyList(param, true);
      if (res && res.code == 200) {
        if (res.data && res.data.list && res.data.list.length > 0) {
          let arr = [{ id: 0, class_name: "New" }];
          let ids = [];
          for (let i = 0; i < res.data.list.length; i++) {
            let item = res.data.list[i];
            if (/^[a-zA-Z0-9 ,.'"\-\s]+$/.test(item.class_name)) {
              arr.push(item);
              ids.push(item.id);
            }
          }
          this.reportClass = arr;
          this.reportClassStr = ids.join();
          this.getReportList();
        }
      }
    },
    // 改变分类
    changeClass(e) {
      this.selectedClass = e;
      this.currentPage = 1;
      this.dataList = [];
      this.getReportList();
    },
    // 获取研究报告数据列表
    async getReportList() {
      this.loading = true;
      let param = {
        pageSize: this.page.pageSize,
        currentPage: this.page.current,
        search: "",
        searchFields: "",
        orderBy: this.page.orderBy,
        sortedBy: this.page.sortedBy
      };
      if (this.keywords && this.keywords.length > 0) {
        param.search = `${param.search}product_name:${this.keywords}|`;
        param.searchFields = `${param.searchFields}product_name:like|`;
      }
      if (this.selectedClass && this.selectedClass > 0) {
        param.search = `${param.search}product_class_id:${this.selectedClass}`;
        param.searchFields = `${param.searchFields}product_class_id:=`;
      } else {
        param.search = `${param.search}product_class_id:${this.reportClassStr}`;
        param.searchFields = `${param.searchFields}product_class_id:in`;
      }
      let res = await reportList(param, true);
      if (res.code == 200) {
        if (this.isMobile) {
          this.dataList = this.dataList.concat(res.data.list);
        } else {
          this.dataList = res.data.list;
        }
        this.page.total = res.data.total;
      }
      this.loading = false;
    },
    // 分页，选中的分页
    handleCurrentChange(val) {
      this.page.current = val;
      this.getReportList();
    },
    // 加载更多
    dataMore() {
      if (this.page.total > this.page.current*this.page.pageSize) {
        this.page.current = this.page.current + 1;
        this.getReportList();
      } else {
        this.noneMore = true;
      }
    },
    // 去除HTML标签
    removeHtmlTags(value) {
      return value ? value.replace(/<[^>]+>/g, "").replace(/&nbsp;/g, '') : "暂无简介";
    },
    // 获取专题列表
    async getSubjectList() {
      let param = {
        pageSize: 50,
        currentPage: 1,
        orderBy: "sort",
        sortedBy: "desc",
        search: "is_show:true|language:2",
        searchFields: "is_show:=|language:=",
      };
      let res = await subjectList(param);
      if (res && res.code == 200) {
        this.reportSubject = res.data.list;
        if (!this.reportSubject || this.reportSubject.length == 1) {
          this.swiperOptions.autoplay = false;
        } else {
          this.swiperOptions.autoplay = true;
        }
        this.$nextTick(() => {
          if (this.$refs.carouselSwiper) {
            this.$refs.carouselSwiper.initSwiper();
          }
        });
      }
    },
    // 订阅
    subscribe() {
      this.$refs.webHeaderRef.subscribe();
    },
  }
}
</script>

<style lang="scss" scoped>
.english {
  font-family: Georgia;
  &-wrap {
    width: 1200px;
    margin: 0 auto;
    &-head {
      margin: 70px 0 40px 0;
      display: flex;
      justify-content: space-between;
      &-tab {
        flex: 1;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        &-cell {
          color: #ed6f33;
          cursor: pointer;
          font-family: Roboto_Light;
          font-size: 22px;
          margin-bottom: 20px;
          margin-right: 40px;
          &:hover, &.active {
            font-family: Roboto_Bold;
            border-bottom: 2px solid #ed6f33;
          }
        }
      }
      &-search {
        min-width: 400px;
        ::v-deep {
          .el-input__inner {
            padding: 0 50px;
            font-size: 16px;
            height: 46px;
            line-height: 46px;
            border-radius: 46px;
            background-color: #f2f2f2;
          }
          .el-input__icon {
            width: 40px;
            color: #8899a6;
            font-size: 20px;
            line-height: 46px;
          }
        }
      }
    }
    &-content {
      display: flex;
      &-left {
        margin-top: 30px;
        flex: 1;
        &-cell {
          padding: 24px 0;
          border-top: 1px solid #e9e9e9;
          &-title {
            font-size: 22px;
            text-decoration: none;
            color: #231815;
            display: inline-block;
            span {
              cursor: pointer;
              margin-right: 10px;
              font-family: HarmonyOS_Bold;
              &:hover {
                color: #ed6f33;
              }
            }
            img {
              max-height: 23px;
              display: inline-block;
              vertical-align: bottom;
            }
          }
          &-desc {
            position: relative;
            margin: 35px 0;
            font-size: 14px;
            color: #909399;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            line-height: 30px;
            max-height: 90px;
            -webkit-line-clamp: 3;
            overflow: hidden;
            text-overflow: ellipsis;
            box-sizing: border-box;
          }
          &-tag {
            font-size: 12px;
            font-family: HarmonyOS_Bold;
            color: #747474;
            span {
              &:before {
                content: "|";
                font-family: HarmonyOS_Regular;
                margin: 0 20px;
              }
              &:first-child {
                &:before {
                  content: "";
                  margin: 0;
                }
              }
            }
          }
        }
      }
      &-right {
        margin-left: 50px;
        max-width: 400px;
        &-card {
          margin-bottom: 50px;
          &-title {
            font-family: Roboto_Bold;
            color: #757575;
            padding-bottom: 10px;
            border-bottom: 1px solid #e9e9e9;
          }
          .carousel-swiper {
            width: 100%;
            margin-top: 30px;
            ::v-deep {
              .swiper-pagination {
                width: calc(100% - 20px);
                right: 20px;
                text-align: right;
              }
              .swiper-pagination-bullet-active {
                background-color: #ed6f33;
              }
            }
          }
          &-desc {
            font-family: HarmonyOS_Regular;
            font-size: 13px;
            line-height: 24px;
            color: #808080;
            margin: 15px 0 30px 0;
          }
          &-icon {
            display: flex;
            flex-wrap: wrap;
            .qrcode {
              max-width: 120px;
            }
          }
          &-opera {
            a {
              padding: 5px 20px;
              font-size: 12px;
              font-family: Roboto_Bold;
              color: #ed6f33;
              border: 1px solid #ed6f33;
              border-radius: 3px;
              text-decoration: none;
              display: inline-block;
              margin-left: 30px;
              &:first-child {
                margin-left: 0;
              }
              &:hover {
                color: #ffffff;
                background-color: #ee8350;
              }
            }
          }
        }
      }
      &-page {
        display: none;
      }
    }
    &-pagination {
      text-align: center;
      margin: 50px 0;
    }
  }
  &-join {
    position: fixed;
    width: 100%;
    bottom: 0;
    color: #ffffff;
    padding: 15px 0;
    text-align: center;
    background-color: #ed6f33;
    z-index: 3000;
    &-describe {
      margin: 0 auto;
      &-header {
        margin-top: 10px;
        font-size: 32px;
        font-family: Georgia_Bold;
      }
      &-tip {
        margin-top: 10px;
        color: #f3f0f0;
      }
      &-btn {
        font-family: Georgia_Bold;
        color: #ed6f33;
        margin-top: 20px;
        cursor: pointer;
        border-radius: 30px;
        padding: 5px 30px;
        display: inline-block;
        user-select: none;
        background-color: #ffffff;
      }
    }
    &-close {
      position: absolute;
      right: 26%;
      top: 15px;
      width: 30px;
      height: 30px;
      border-radius: 30px;
      border: 1px solid #ffffff;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 24px;
      }
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .english {
    &-wrap {
      width: calc(100% - 60px);
      padding: 0 30px;
      &-head {
        margin-top: 30px;
        flex-direction: column-reverse;
        &-tab {
          margin-top: 50px;
          &-cell {
            font-size: 18px;
          }
        }
      }
      &-content {
        flex-direction: column;
        &-left {
          margin-top: 30px;
        }
        &-right {
          max-width: 100%;
          margin-left: 0;
        }
      }
    }
    &-join {
      &-describe {
        &-header {
          font-size: 28px;
        }
        &-tip, &-btn {
          font-size: 16px;
        }
      }
      &-close {
        right: 10%;
      }
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .english {
    &-wrap {
      width: calc(100% - 60px);
      padding: 0 30px;
      &-head {
        margin-top: 30px;
        flex-direction: column-reverse;
        &-tab {
          margin-top: 30px;
          &-cell {
            font-size: 14px;
          }
        }
        &-search {
          min-width: auto;
        }
      }
      &-content {
        flex-direction: column;
        &-left {
          margin-top: 10px;
          &-cell {
            &-title {
              font-size: 18px;
            }
          }
        }
        &-right {
          max-width: 100%;
          min-width: auto;
          margin-left: 0;
        }
        &-page {
          margin: 40px auto 30px auto;
          display: table;
          .none-more {
            font-size: 12px;
            padding: 5px 15px;
            border: 1px solid #DCDFE6;
          }
          ::v-deep {
            .el-button--danger {
              background-color: #ed6f33;
              border-color: #ed6f33;
            }
          }
        }
      }
      &-pagination {
        display: none;
      }
    }
    &-join {
      &-describe {
        &-header {
          font-size: 20px;
        }
        &-tip, &-btn {
          font-size: 14px;
        }
      }
      &-close {
        right: 15px;
        width: 20px;
        height: 20px;
        border-radius: 20px;
        i {
          font-size: 14px;
        }
      }
    }
  }
}
</style>